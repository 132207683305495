import '@/commons';
import { Component, Vue } from 'vue-property-decorator';
import Header from './header';
import Footer from './footer';
import store from '@/store/index';
import { Debug } from '@/shared/utils/helpers';

const header = Header;
const footer = Footer;

@Component({})
class DefaultPage extends Vue {
    mounted() {
        Debug.log('Default Page mounted');
    }
}
if (document.getElementById('default')) {
    const d = new DefaultPage({
        store,
        el: '#default',
        delimiters: ['[{', '}]'],
    });
}
